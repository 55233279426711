import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

import { CheckIcon } from "@heroicons/react/solid";

const tiers = [
  {
    name: "Platinum",
    href: "#",
    priceMonthly: "10,000",
    description: "",
    includedFeatures: [
      "Invitation to the Gala Dinner with one premier table, near to stage, of 10 seats",
      "Table comes with red wine",
    ],
  },
  {
    name: "Gold",
    href: "#",
    priceMonthly: "7,500",
    description: "",
    includedFeatures: [
      "Invitation to the Gala Dinner with one table of 10 seats",
      "Table comes with red wine",
    ],
  },
  {
    name: "Silver",
    href: "#",
    priceMonthly: "5,000",
    description: "",
    includedFeatures: [
      "Invitation to the Gala Dinner with one table of 10 seats ",
      "Table comes with red wine",
    ],
  },
  {
    name: "Bronze",
    href: "#",
    priceMonthly: "3,500",
    description: "",
    includedFeatures: [
      "Invitation to the Gala Dinner with one table of 10 seats ",
      "Table comes with red wine",
    ],
  },
];

const metrics = [
  {
    id: 1,
    stat: "8K+",
    emphasis: "Companies",
    rest: "use laoreet amet lacus nibh integer quis.",
  },
  {
    id: 2,
    stat: "25K+",
    emphasis: "Countries around the globe",
    rest: "lacus nibh integer quis.",
  },
  {
    id: 3,
    stat: "98%",
    emphasis: "Customer satisfaction",
    rest: "laoreet amet lacus nibh integer quis.",
  },
  {
    id: 4,
    stat: "12M+",
    emphasis: "Issues resolved",
    rest: "lacus nibh integer quis.",
  },
];

const Table = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Sponsorship opportunities"
          description="SWA welcome sponsorships
                      and financial support from individuals and corporate
                      member volunteers for our charity efforts."
        />
        <section className="max-w-5xl px-2 pt-3 mx-auto sm:w-4/5">
          <div className="bg-white">
            <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="sm:flex sm:flex-col sm:align-center">
                <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
                  Table Donations
                </h1>
                <p className="mt-5 text-xl text-gray-500 sm:text-center">
                  Sponsor a table for the 46th Annual Charity Gala Dinner 2023.
                </p>
                {/* <h2 className="mt-8 text-lg text-center text-gray-600">
                  <span className="block">
                    Sponsorships for 45th Annual Charity Dinner is closed.
                  </span>
                  Please contact us if you would like to discuss other
                  sponsorship opportunities.

                </h2> */}
                <div className="flex mt-4">
                  <div className="inline-flex mx-auto rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
                {/* <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
                  <button
                    type="button"
                    className="relative w-1/2 py-2 text-sm font-medium text-gray-900 bg-white border-gray-200 rounded-md shadow-sm whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8"
                  >
                    Monthly billing
                  </button>
                  <button
                    type="button"
                    className="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8"
                  >
                    Yearly billing
                  </button>
                </div> */}
              </div>
              <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
                {tiers.map(tier => (
                  <div
                    key={tier.name}
                    className="border border-gray-200 divide-y divide-gray-200 rounded-lg shadow-sm"
                  >
                    <div className="p-6">
                      <h2 className="text-lg font-bold leading-6 text-swa-1">
                        {tier.name}
                      </h2>
                      <p className="mt-4 text-sm text-gray-500">
                        {tier.description}
                      </p>
                      <p className="mt-8">
                        <span className="text-4xl font-extrabold text-gray-900">
                          ${tier.priceMonthly}
                        </span>{" "}
                        {/* <span className="text-base font-medium text-gray-500">
                          /mo
                        </span> */}
                      </p>
                      {/* <a
                        href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2Fsponsorreply.pdf?alt=media&token=0490ba67-d682-4867-b7b8-a388a8c8457e"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block w-full py-2 mt-8 text-sm font-semibold text-center text-white border border-gray-800 rounded-md bg-swa-1 hover:bg-gray-900"
                      >
                        Download form
                      </a> */}
                    </div>
                    <div className="px-6 pt-6 pb-8">
                      <h3 className="text-xs font-medium tracking-wide text-gray-900 uppercase">
                        What's included
                      </h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {tier.includedFeatures.map(feature => (
                          <li key={feature} className="flex space-x-3">
                            <CheckIcon
                              className="flex-shrink-0 w-5 h-5 text-swa-1"
                              aria-hidden="true"
                            />
                            <span className="text-sm text-gray-500">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                <h2 className="text-xl text-gray-600 sm:text-xl">
                  <span className="block">
                    Other sponsorship types available
                  </span>
                </h2>
                <div className="flex mt-4">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/participate/corporate"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                    >
                      Corporate sponsorship
                    </Link>
                  </div>
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/participate/advertisement"
                      className="inline-flex items-center justify-center px-5 py-3 ml-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                    >
                      Advertisement
                    </Link>
                  </div>
                </div>
                <h2 className="mt-8 text-lg text-gray-600 sm:text-lg">
                  <span className="block">
                    Please contact us if you have any questions regarding the
                    sponsorships
                  </span>
                </h2>
                <div className="flex mt-4">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Table;
